import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import axios from 'axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function Searchui() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selection, setSelection] = useState('context');
  const [activeItem , setActiveItem] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [invalidFiles,setInvalidFiles] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectionMethod,setSelectionMethod] = useState('AND');
  const [searchResults,setSearchResults] = useState([]);
  const [documentText, setDocumentText] = useState('');
  const [searchHappened, setSearchHappened] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [activeTab, setActiveTab] = useState('tab1');
  const [browsefiles,setBrowseFiles] = useState([]);
  const [activeBrowseItem, setActiveBrowseItem] = useState('');
  const [browseDocumentText, setBrowseDocumentText] = useState('');
  const [fileToDelete, setFileToDelete] = useState(null);
  // Ref for the right view container
  const rightViewRef = useRef(null);
  const navigate = useNavigate();

  const logoutFormRef = useRef(null);
  const [fileCount, setFileCount] = useState(null); // State to store file count
  const [polling, setPolling] = useState(true); // State to control polling


  // Fetch the hash value and submit the logout form
  const handleLogout = async () => {
    const requestUrl = "https://sga-tscripts.isha.in";
    const apiKey = "31d9c883155816d15f6f3a74dd79961b0577670ac";
    const callbackUrl = "https://sga-tscripts.isha.in/api/logout_callback";
    try {
      const response = await fetch('https://sga-tscripts.isha.in/api/generate_hash', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          request_url: requestUrl,
          callback_url: callbackUrl,
          api_key: apiKey,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        const hash_value = result.hash_value;

        // Set form values
        logoutFormRef.current.request_url.value = requestUrl;
        logoutFormRef.current.hash_value.value = hash_value;
        logoutFormRef.current.logout_callback_url.value = callbackUrl;

        sessionStorage.removeItem('session_id');
        // Submit the logout form
        logoutFormRef.current.submit();
      } else {
        console.error('Failed to fetch hash value:', result.error);
      }
    } catch (error) {
      console.error('Error fetching hash value:', error);
    }
    navigate('/');
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  const ILA_BASE_URL = "https://sga-tscripts.isha.in/api";
  //const ILA_BASE_URL = "http://localhost:8081";
  // Handle changing the active tab
  const handleTabChange = (val) => {
    setActiveTab(val); 
  };
  
  const getFiles = async () => {
    try {
      const response = await fetch(ILA_BASE_URL+'/files');
      const data = await response.json();
      console.log(data);
      // Check if the response status is 'success'
      if (data.status === 'success') {
        // Update the state with the files from the response 
        setBrowseFiles(data.files);
      } else {
        console.error('Failed to fetch files:', data.message);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const scrollToFirstYellowSpan = () => {
    if (rightViewRef.current) {
      const firstYellowSpan = rightViewRef.current.querySelector('.bg-yellow-200');
      if (firstYellowSpan) {
        const spanTop = firstYellowSpan.offsetTop;
        const spanHeight = firstYellowSpan.offsetHeight;
        const containerHeight = rightViewRef.current.clientHeight;
        const scrollPosition = spanTop - (containerHeight / 2) + (spanHeight / 2);
  
        //console.log(`Height of the first yellow span: ${spanHeight}px`);
        rightViewRef.current.scrollTo(0, scrollPosition);
      }
    }
  };

  useEffect(() => {
    //console.log('useEffect triggered with documentText:', documentText);
    if (documentText) {
      scrollToFirstYellowSpan();

    }
  }, [documentText]);

  useEffect(() => {
    // This function will run only once when the component mounts
    getFiles();
    fetchUploadFileCount();
  }, []); 

  // Start polling every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (polling) {
        fetchUploadFileCount();
      }
    }, 2000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [polling]);

  const showTextClick = async (filename, matches) => {
    setActiveItem(filename);
    const formData = new FormData();
    formData.append('filename',filename);
    try {
      const response = await axios.post(ILA_BASE_URL+'/showtext', formData);
      console.log(response.data);
      var status = response.data.status;
      if (status === "success"){
        var full_text = response.data.text
        matches.forEach( (matchx) =>{ 
          var match = escapeRegExp(matchx);
          var dynamicRegex = new RegExp(match, 'gi'); 
          full_text = full_text.replace(dynamicRegex, '<span class="bg-yellow-200">'+matchx+'</span>')
        });
        full_text = full_text.replace(/\n/g, '<br/>');
        setDocumentText(full_text)
      } else {
        // Error message show
      }
      // Handle success
    } catch (error) {
      console.error('Error uploading files:', error);
      setInvalidFiles("Could not upload files : \n"+error)
      // Handle error
    } finally {
      setIsUploading(false); // End upload
    }

  }

  const showBrowseClick = async (filename) => {
    setActiveBrowseItem(filename);
    const formData = new FormData();
    formData.append('filename',filename);
    try {
      const response = await axios.post(ILA_BASE_URL+'/showtext', formData);
      console.log(response.data);
      var status = response.data.status;
      if (status === "success"){
        var full_text = response.data.text 
        full_text = full_text.replace(/\n/g, '<br/>');
        setBrowseDocumentText(full_text)
      } else {
        // Error message show
        alert('Document could not be fetched');
      }
      // Handle success
    } catch (error) {
      console.error('Error uploading files:', error);
      
      // Handle error
    } finally { 

    }

  }

  const deleteFile = async (filename) => {
    // Logic to delete the file
    console.log(`Deleting file: ${filename}`);
    const formData = new FormData();
    formData.append('filename',filename);

    try {
        const response = await axios.post(ILA_BASE_URL+'/delete_file', formData, {
      });
        console.log(response.data);
        var status = response.data.status;
        if (status === "success"){
          console.log('Delete success: ', response.data.reason);
        } else {
          console.error('Delete failure: ', response.data.reason);
        }
        // Handle success
      } catch (error) {
        console.error('Error deleting file:', error);
        // Handle error
      } finally {
        //setIsUploading(false); // End upload
        window.location.reload();
      }

      // Update the state to remove the file from browsefiles
    };
   
  // Function to fetch the file count from the API
  const fetchUploadFileCount = async () => {
    try {
      const response = await axios.get(ILA_BASE_URL+'/count_files_in_uploads');
      const count = response.data.num_files;

      // If count is non-zero, update state and continue polling
      if (count > 0) {
        setFileCount(count);
        setPolling(true);
      } else {
        setFileCount(0);
        setPolling(false); // Stop polling if count is 0
      }
    } catch (error) {
      console.error("Error fetching file count:", error);
      setPolling(false); // Stop polling if there's an error
    }
  };


  const searchThis = async () => {
    setDocumentText('');
    setSearchResults([]); 
    setSearchHappened(true);
    try { 
      setIsSearching(true);
      const formDataX= new FormData();
      formDataX.append('search_items',searchTerm)
      formDataX.append('search_type',selection)
      formDataX.append('search_method',selectionMethod)
      formDataX.append('before',moment(startDate).format('DD/MM/YYYY'));
      formDataX.append('after',moment(endDate).format('DD/MM/YYYY'));
      const response = await axios.post(ILA_BASE_URL+'/search',formDataX);
      // Process the result of your API call
      console.log(response.data);
      if (response.data.status === "success"){
        setSearchResults(response.data.search_results);
        console.log(response.data.search_results);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setIsSearching(false);
    }
  }
  const handleKeyDown = (event) => {
   
    if (event.key === 'Enter') {
    searchThis();
    }
  };
  const handleFileChange = (event) => {
    setInvalidFiles('')
    const files = Array.from(event.target.files);
    let invalidFiles = '';
  
    if (files.length > 1000) {
        setSelectedFiles([])
      invalidFiles = 'Only 1000 files can be processed at once';
    } else if (!files.every(file => file.name.endsWith('.docx'))) {
        setSelectedFiles([])
        invalidFiles = 'All files need to be docx';
    } else {
      setSelectedFiles(files.map(file => file));
    }
    setInvalidFiles(invalidFiles); // Assuming you have a state setter for invalidFiles
  };
  
  const [isUploading, setIsUploading] = useState(false);
  // Update visibility based on selection
  const isProgramDateSelected = selection === 'program';

const uploadFiles = async () => {
  const BATCH_SIZE = 20; // Set batch size (e.g., 4 files per batch)
  setInvalidFiles('');

  if (selectedFiles.length > 1000) {
    setInvalidFiles('Too many files selected. Please upload up to 100 files.');
    return;
  }

  setIsUploading(true); // Start upload

  // Helper function to upload a single batch
  const uploadBatch = async (batchFiles) => {
    const formData = new FormData();
    batchFiles.forEach(file => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(ILA_BASE_URL + '/upload_files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        maxContentLength: 100 * 1024 * 1024, // 100MB
        maxBodyLength: 100 * 1024 * 1024,    // 100MB
      });

      console.log(response.data);
      return response.data.status === "success";
    } catch (error) {
      console.error('Error uploading batch:', error);
      throw new Error(`Could not upload files: ${error}`);
    }
  };

  // Divide selected files into batches
  const uploadInBatches = async () => {
    for (let i = 0; i < selectedFiles.length; i += BATCH_SIZE) {
      const batchFiles = Array.from(selectedFiles).slice(i, i + BATCH_SIZE);
      const isSuccess = await uploadBatch(batchFiles);

      if (!isSuccess) {
        setInvalidFiles('Could not upload batch of files.');
        return false;
      }
    }
    return true;
  };

  try {
    const isUploadSuccessful = await uploadInBatches();

    if (isUploadSuccessful) {
      // Call process_files_inbackground API after all batches are uploaded
      const processResponse = await axios.post(ILA_BASE_URL + '/process_files_inbackground');
      if (processResponse.data.status === "success") {
        setSelectedFiles([]);
        setInvalidFiles("Upload Successful; Files are now being processed on the server!");
        fetchUploadFileCount();
      } else {
        setInvalidFiles("Could not process files: " + processResponse.data.reason);
      }
    } else {
      setInvalidFiles("File upload failed.");
    }
  } catch (error) {
    setInvalidFiles("Error uploading files or processing: " + error.message);
    console.error('Error:', error);
  } finally {
    setIsUploading(false); // End upload
  }
};


  const uploadFiles2 = async () => {
    setInvalidFiles('')
    if (selectedFiles.length > 100) {
      setInvalidFiles('Too many files selected. Please upload up to 100 files.');
      return;
    }
    setIsUploading(true); // Start upload
    const formData = new FormData();
    Array.from(selectedFiles).forEach(file => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(ILA_BASE_URL + '/upload_files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      maxContentLength: 100 * 1024 * 1024,  // 100MB
      maxBodyLength: 100 * 1024 * 1024,  // 100MB
      });
      console.log(response.data);
      var status = response.data.status;
      if (status === "success"){
        setSelectedFiles([]);
        setInvalidFiles( "Upload Successful; Files are now being processed on the server!")
      } else {
        setInvalidFiles( "Could not upload files: \n"+ response.data.reason)
      }
      // Handle success
    } catch (error) {
      console.error('Error uploading files:', error);
      setInvalidFiles("Could not upload files : \n"+error)
      // Handle error
    } finally {
      setIsUploading(false); // End upload
    }
  };


  const handleDeleteClick = async (filename) => {
    setFileToDelete(filename);
  };

  const confirmDelete = async () => {
    if (fileToDelete) {
      deleteFile(fileToDelete);
      setFileToDelete(null);
    }
  };

  const cancelDelete = async () => {
    setFileToDelete(null);
  };

  return (
    <div className="mainContainer overflow-hidden"> 
    <div className="navbar bg-base-100">
  <div className=" navbar-start flex-1">
    <a className="btn btn-ghost text-xl">SGA Transcript Search</a>
  </div>

  <div className="navbar-center hidden lg:flex">
    <ul className="menu menu-horizontal px-1">
      <li> <a className={activeTab === 'tab1' ? 'active' : ''} onClick={() => handleTabChange('tab1')}>Browse</a></li>
      <li> <a className={activeTab === 'tab2' ? 'active' : ''} onClick={() => handleTabChange('tab2')}>Search</a></li>
    </ul>
  </div>
<div className='navbar-end'>
      {fileCount > 0 && (
        <span>
          <p style={{marginRight: '20px', color: 'blue', fontSize: '14px' }}> <i className="fas fa-spinner fa-spin" style={{ marginRight: '8px' }}></i> Processing uploads..  {fileCount} </p>
        </span>
      )}
      <div className="tooltip tooltip-bottom mr-10" data-tip="Upload Transcripts">
          <button className="btn btn-square btn-outline ml-2" disabled={fileCount > 0}  onClick={()=>document.getElementById('my_modal_1').showModal()} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
          </svg>

         </button>
      </div>
      
      <button
        style={{
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          fontSize: '16px',
          color: '#000', // Black text color
          padding: '10px 20px', // Padding for a better look
        }}
        onClick={handleLogout}
      >
        Logout
      </button>

</div>
</div>  {/*TAbs */}
        {activeTab === 'tab1' && <div className="bg-base-100 border-base-300 rounded-box p-6 w-screen">
          {/* DIV */ } 
          <div className="flex h-screen border-t border-gray-200 overflow-hidden w-screen">
    {/* Sidebar with vertical scrolling and a light right border */}
    <div className="w-1/3 overflow-y-auto p-4" style={{ marginBottom: '100px' }}>
      {/* Display the number of files */}
      <div className="mb-4">
        <p className="text-sm text-gray-500">Total files: {browsefiles.length}</p>
      </div>
      <ul className="menu w-full rounded-box">
          {browsefiles.map((filename, index) => (
            <li
              key={filename}
              className={`menu-item ${activeBrowseItem === filename ? 'bg-base-300' : ''}`}
            >
              <div className="flex justify-between items-center">
                <a onClick={() => showBrowseClick(filename)}>{filename}</a>
                <button
                  className="btn btn-xs btn-primary ml-2"
                  onClick={() => handleDeleteClick(filename)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
    </div>

    {/* Content Area with vertical scrolling */}
    <div className="w-2/3 overflow-y-auto p-4 mb-4" style={{ height: 'calc(100vh - 100px)' , paddingBottom: '4rem'}}>
      <div className="pt-2">
       <div dangerouslySetInnerHTML={{ __html: browseDocumentText }} /> 
      </div>
    </div>
  </div>

           {/* DIV */ } 
          
          </div>}
  {activeTab === 'tab2' &&
  <div className=" w-screen">
      <div className="navbar bg-base-100 mt-2.5"> 
         
        <div className="navbar-center w-3/4">
          {isProgramDateSelected ? (
            <>
            <div className="flex justify-center gap-8 "> {/* Adjusted for alignment */}
              {/* "Before" date picker and label */}
              <div className="flex items-center gap-2"> {/* Flex container for alignment */}
                <label htmlFor="after-datepicker" className="block text-sm font-medium text-gray-700">
                  After:
                </label>
                <DatePicker
                  id="after-datepicker"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate} 
                  dateFormat="dd/MM/yyyy"
          showYearDropdown
          yearDropdownItemNumber={15} // Optional: Adjust number of years to display
          scrollableYearDropdown 
                  className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              {/* "After" date picker and label */}
              <div className="flex items-center gap-2"> {/* Flex container for alignment */}
                <label htmlFor="before-datepicker" className="block text-sm font-medium text-gray-700">
                  Before:
                </label>
                <DatePicker
                  id="before-datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
          showYearDropdown
          yearDropdownItemNumber={15} // Optional: Adjust number of years to display
          scrollableYearDropdown 
                  className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
             
            </div>
            <button className="btn btn-square btn-outline ml-3 mr-2" onClick={searchThis}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" /></svg>
</button>
</>
          ) : (
            <>
            <label className="input input-bordered flex items-center gap-2 w-full">
               <input
      type="text"
      className="grow"
      placeholder="Search"
      value={searchTerm}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
    /> 
            </label>
            <button className="btn btn-square btn-outline ml-3 mr-2" onClick={searchThis}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" /></svg>
</button>

            { selection === 'terms' ?
            <select 
            className="select select-bordered  ml-2"
            value={selectionMethod}
            onChange={(e) => setSelectionMethod(e.target.value)}
          >
            <option value="AND">Find All (AND)</option>
            <option value="OR">Find Any (OR)</option> 
          </select> : ''
}
          </>
          )}
          <select 
            className="select select-bordered w-full max-w-xs ml-2"
            value={selection}
            onChange={(e) => setSelection(e.target.value)}
          >
            <option value="context">Search in Context</option>
            <option value="terms"> Search in text</option>
            <option value="program">Program Date</option>
          </select>
          
        </div>
 
      </div>
<div>&nbsp;</div>
      {isSearching ? (
  <div className="flex h-screen items-center justify-center">
    <div className="alert   ">
      <div>
        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
          {/* Spinner Icon from Heroicons (https://heroicons.com/) */}
        </svg>
        <span>Loading...</span>
      </div>
    </div>
  </div>
) : Object.keys(searchResults).length > 0 ? (
  <div className="flex h-screen border-t border-gray-200 overflow-hidden">
    {/* Sidebar with vertical scrolling and a light right border */}
    <div className="w-1/3 overflow-y-auto p-4">
      <ul className="menu w-full rounded-box">
        {Object.keys(searchResults).map((filename) => (
          <li
            key={filename}
            className={`menu-item ${activeItem === filename ? 'bg-base-300' : ''}`}
            onClick={() => showTextClick(filename, searchResults[filename])}
          >
            <a>{filename}</a>
          </li>
        ))}
      </ul>
    </div>

    {/* Content Area with vertical scrolling */}
    <div
            className="w-2/3 overflow-y-auto p-4 mb-4"
            style={{ height: 'calc(100vh - 100px)', paddingBottom: '6rem' }}
            ref={rightViewRef}
          >
            <div className="pt-2">
              <div dangerouslySetInnerHTML={{ __html: documentText }}></div>
            </div>
          </div>
  </div>
) : searchHappened === true ? (
  <div className="toast toast-center toast-middle">
    <div className="alert alert-error">
      <span>No matches found!</span>
    </div>
  </div>
) : (
  ''
)}




</div> 
//  Search Panel 
} {/* search tab ends here */}




{/* MODAL */}

{/* Confirmation Modal */}
{fileToDelete && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
      <p>Are you sure you want to delete the file "{fileToDelete}"?</p>
      <div className="mt-4 flex justify-end">
        <button className="btn btn-secondary mr-2" onClick={cancelDelete}>
          Cancel
        </button>
        <button className="btn btn-error" onClick={confirmDelete}>
          Delete
        </button>
      </div>
    </div>
  </div>
)}

<dialog id="my_modal_1" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-lg">Upload Transcripts</h3>
    <p className="py-4"> 
    <label className={`btn btn-primary ${isUploading && 'btn-disabled'}`} htmlFor="file-upload">Upload Files</label>
    <input type="file" id="file-upload" className="hidden" multiple onChange={handleFileChange} disabled={isUploading} accept=".docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />

      </p>
    <ul className="list-disc list-inside p-4 mt-2">
  {selectedFiles.map((fileName, index) => (
    <li key={index} className="border-b border-primary-focus p-2">{fileName.name}</li>
  ))}
</ul> 
{invalidFiles !== '' ? <div className="toast toast-center toast-middle">
  <div className="alert alert-info">
    <span>{invalidFiles}</span>
  </div></div>  : ''   }  

       
        <div className="modal-action justify-between">
  <form method="dialog" className="flex justify-between w-full">
  {
  selectedFiles.length > 0 && (
    isUploading ? <div style={{ display: 'flex', alignItems: 'center' }}>
  			<i className="fas fa-spinner fa-spin" style={{ marginRight: '8px' }}></i>
  			Uploading... Please wait; <br/>this may take a while to complete.
		  </div> : <button onClick={uploadFiles} className="btn btn-secondary" disabled={isUploading}>Send Files</button> 
  )
}
{  isUploading ? '' : <button className="btn">Close</button>}



  </form>
  <form action="https://ishalogin.sadhguru.org/cm" method="post" id="logout_form" ref={logoutFormRef}>
    <input type="hidden" name="request_url" id="logout_request_url" value="" />
    <input type="hidden" name="api_key" id="api_key" value="31d9c883155816d15f6f3a74dd79961b0577670ac" />
    <input type="hidden" name="hash_value" id="logout_hash_value" value="" />
    <input type="hidden" name="logout_callback_url" id="logout_callback_url" value="" />
    <input type="hidden" name="enable_redirect" id="enable_redirect" value="1" />
  </form>
</div>

  </div>
</dialog>
{/* Modal */}

{/* container */}
    </div>



  );
}

export default Searchui;
