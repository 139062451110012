import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Searchui from './searchpage';
import Login from './Login';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const sessionId = sessionStorage.getItem('session_id');
    if (sessionId) {
      console.log('Session ID found in sessionStorage:', sessionId);
      setIsAuthenticated(true);
    } else {
      console.log('No session ID found in sessionStorage');
    }
  }, []);

  const handleLogin = (sessionData) => {
    console.log('Storing session data:', sessionData);
    sessionStorage.setItem('session_id', sessionData.session_id);
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main isAuthenticated={isAuthenticated} onLogin={handleLogin} />} />
      </Routes>
    </Router>
  );
}

async function getProfileId(apiKey, sessionId, hashApiUrl, getLoginInfoUrl) {
  try {
    const loginInfoResponse = await fetch("https://sga-tscripts.isha.in/api/get_entitlement_ids/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ session_id: sessionId })
    });

    if (!loginInfoResponse.ok) {
      throw new Error(`Failed to get entitlements info: ${loginInfoResponse.statusText}`);
    }

    const loginInfoData = await loginInfoResponse.json();
    const entitlement = loginInfoData.entitlement;
    console.log("Entitlement: ", entitlement);
    return entitlement;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

function Main({ isAuthenticated, onLogin }) {
  const location = useLocation();
  const loginAttempted = useRef(false);

  useEffect(() => {
    const processLogin = async () => {
      if (loginAttempted.current) {
        return;
      }
      loginAttempted.current = true;

      console.log('Current location:', location);
      const params = new URLSearchParams(location.search);

      console.log('URL parameters received on callback:');
      params.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });

      const sessionId = params.get('session_id');

      if (sessionId) {
        console.log('Session ID found in URL params:', sessionId);
        const sessionData = {
          api_key: params.get('api_key'),
          request_url: params.get('request_url'),
          session_id: sessionId,
          hash_value: params.get('hash_value'),
          force_consent: params.get('force_consent'),
          consent_grant_status: params.get('consent_grant_status'),
          legal_entity: params.get('legal_entity'),
        };

        console.log('Session data received:', sessionData);

        try {
          const entitlement = await getProfileId(sessionData.api_key, sessionId, 'https://sga-tscripts.isha.in/api/getlogininfo_hash', 'https://ishalogin.sadhguru.org/getlogininfo');
          console.log('Entitlement:', entitlement);
          if (entitlement.toLowerCase() === "yes") {
            onLogin(sessionData);
          } else {
            const emptySessionData = {
              api_key: '',
              request_url: '',
              session_id: '',
              hash_value: '',
              force_consent: '',
              consent_grant_status: '',
              legal_entity: ''
            };
            onLogin(emptySessionData);
            window.location.href = '/unauthorized-access';
            alert("Unauthorised Access! You do not have enough permission to access the application. Please contact the admin of the app.");
          }
        } catch (error) {
          console.error('Error during login process:', error);
        }
      } else {
        console.log('No session ID found in URL params');
      }
    };

    processLogin();
  }, [location, onLogin]);

  return isAuthenticated ? <Searchui /> : <Login />;
}

export default App;
