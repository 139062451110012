import React, { useEffect, useRef } from 'react';

function Login({ onLogin }) {
  const requestUrlRef = useRef(null);
  const callbackUrlRef = useRef(null);
  const apiKeyRef = useRef(null);
  const hashValueRef = useRef(null);
  const actionRef = useRef(null);
  const legalEntityRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    const fetchHashAndSubmitForm = async () => {
      const requestUrl = "https://sga-tscripts.isha.in";
      const callbackUrl = "https://sga-tscripts.isha.in/api/sso_callback";  // Ensure this matches your local development URL
      const apiKey = '31d9c883155816d15f6f3a74dd79961b0577670ac';
      const legalEntity = 'IF';
      const action = '0';

      try {
        const response = await fetch('https://sga-tscripts.isha.in/api/generate_hash', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            request_url: requestUrl,
            callback_url: callbackUrl,
            api_key: apiKey,
          }),
        });

        const result = await response.json();

        if (response.ok) {
          const hash_value = result.hash_value;

          console.log('Form Elements:', {
            requestUrlRef: requestUrlRef.current,
            callbackUrlRef: callbackUrlRef.current,
            apiKeyRef: apiKeyRef.current,
            hashValueRef: hashValueRef.current,
            actionRef: actionRef.current,
            legalEntityRef: legalEntityRef.current,
            formRef: formRef.current,
          });

          requestUrlRef.current.value = requestUrl;
          callbackUrlRef.current.value = callbackUrl;
          apiKeyRef.current.value = apiKey;
          hashValueRef.current.value = hash_value;
          actionRef.current.value = action;
          legalEntityRef.current.value = legalEntity;

          // Temporarily prevent form submission to inspect values
          console.log('Form submission prevented. Inspect values:');
          console.log('request_url:', requestUrl);
          console.log('callback_url:', callbackUrl);
          console.log('api_key:', apiKey);
          console.log('hash_value:', hash_value);
          console.log('action:', action);
          console.log('legal_entity:', legalEntity);

          // Uncomment the next line to submit the form
          formRef.current.submit();
        } else {
          console.error('Failed to fetch hash value:', result.error);
        }
      } catch (error) {
        console.error('Error fetching hash value:', error);
      }
    };

    fetchHashAndSubmitForm();
  }, []);

  return (
    <form id="login_form" action="https://ishalogin.sadhguru.org" method="post" ref={formRef}>
      <input type="hidden" id="login_request_url" name="request_url" ref={requestUrlRef} />
      <input type="hidden" id="callback_url" name="callback_url" ref={callbackUrlRef} />
      <input type="hidden" id="api_key" name="api_key" ref={apiKeyRef} />
      <input type="hidden" id="login_hash_value" name="hash_value" ref={hashValueRef} />
      <input type="hidden" id="action" name="action" ref={actionRef} />
      <input type="hidden" id="legal_entity" name="legal_entity" ref={legalEntityRef} />
    </form>
  );
}

Login.defaultProps = {
  onLogin: () => {},
};

export default Login;

